import React, { useState } from 'react'
import { Grid, Container, CircularProgress, Input, Button } from '@material-ui/core'
import { navigate } from 'gatsby'
import SnackMessage from 'components/snackbar'
import Agent from 'components/agent'
import { put } from 'utils/request'

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [resMessage, setResMessage] = useState('')

  const changePassword = async (params) => {
    const res = await put('agents/passwords', params)
    if (res.ok) {
      navigate('/agent/marketing')
    }
    setOpen(true)
    setLoading(false)
    setResMessage(res.message)
  }

  return (
    <Agent>
      <Container maxWidth="xs" className="pt-32">
        <Grid container>
          <Grid item xs>
            <Input autoFocus type="password" placeholder="原密码" fullWidth onChange={(e) => setOldPassword(e.target.value)} value={oldPassword} />
            <Input type="password" className="mt-6" placeholder="新密码" fullWidth onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
            <Input type="password" placeholder="确认密码" className="mt-6" fullWidth onChange={(e) => setPasswordConfirmation(e.target.value)} value={passwordConfirmation} />
            <Button
              color="primary"
              fullWidth
              variant="contained"
              disabled={loading}
              size="large"
              onClick={() => {
                setLoading(true)
                changePassword({ old_password: oldPassword, new_password: newPassword, password_confirmation: passwordConfirmation })
              }}
              className="mt-8"
            >
              {loading ? <CircularProgress size={24} /> : '提交'}
            </Button>
          </Grid>
        </Grid>
        <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="info" />
      </Container>
    </Agent>
  )
}

export default ChangePassword
